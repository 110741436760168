import { XSwapWidget } from '@xyfinance/widget';

const config = {
  disabledChains: [],
  fromInput: '100',
  fromToken: {
    address: '0xA677a11A6d3abb32F1E195db5650b147577194c6',
    chainId: '56',
  },
  toToken: {
    address: '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
    chainId: '56',
  },
  referrer: '0x49707808908f0C2450B3F2672E012eDBf49eD808',
  commissionRate: 2500,
  featuredTokens: [
    {
      address: '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
      chainId: '56',
    },
    {
      address: '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
      chainId: '56',
    },
    {
      address: '0xA677a11A6d3abb32F1E195db5650b147577194c6',
      chainId: '56',
    },
  ],
};

const theme = {
  mode: 'dark',
  //   fontFamily: 'proxima-nova',
  borderRadius: {
    container: '12px',
    inner: '8px',
    button: '32px',
  },
  colors: {
    primary: '#277EEC',
    gradient: ['#277EEC', '#1499E4'],
  },
  components: {
    button: {
      variant: 'gradient',
    },
  },
};

export default function App() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        margin: '0px auto',
      }}
    >
      <div
        style={{
          width: '480px',
        }}
      >
        <XSwapWidget config={config} theme={theme} />
      </div>
    </div>
  );
}
